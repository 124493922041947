<template>
  <div v-if="!isLoading">
    <div class="profileTab">
      <div class="settingsCont">
        <div class="settingsBlock">
          <h5 class="or-underline">My profile</h5>
          <div class="uploadAvatar"></div>
          <div class="detailsForm">
            <PersonalDetails
              v-if="showDetails"
              :user="user"
              :user-details="userDetails"
            />
            <EditPersonalDetails
              v-else
              :user="user"
              :user-details="userDetails"
              @update="updateUserDetails"
              @update-avatar="updateUserPhotoURL"
            />
            <BaseButton
              :aria-label="showDetails ? 'edit-profile' : 'cancel-edit'"
              modifiers="primary"
              @click="toggleDetails"
            >
              {{ showDetails ? "Edit" : "Cancel" }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="separatorLine"></div>
    <div v-if="user" class="logout-btn clickable">
      <BaseButton modifiers="primary" aria-label="logout" @click="onLogout">
        Log out
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { db } from "@/firebase";
import PersonalDetails from "@/components/settings/personal/PersonalDetails.vue";
import EditPersonalDetails from "@/components/settings/personal/EditPersonalDetails.vue";
import useLogout from "@/composables/useLogout";
import useStorage from "@/composables/useStorage";
import getUser from "@/composables/getUser";
import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: {
    PersonalDetails,
    EditPersonalDetails,
    BaseButton
  },
  setup() {
    const showDetails = ref(true);
    const toggleDetails = () => (showDetails.value = !showDetails.value);
    const { logout } = useLogout();
    const { user, userDetails } = getUser();
    const { url, uploadImage } = useStorage();

    const isLoading = computed(() => !user.value || !userDetails.value);

    const updateUserDetails = async form => {
      console.log(form);
      await db
        .collection("users")
        .doc(userDetails.value.id)
        .update({
          firstName: form.firstName,
          lastName: form.lastName,
          workingHoursPerDay: form.workingHoursPerDay
        });
      showDetails.value = true;
    };

    const updateUserPhotoURL = async file => {
      await uploadImage(file);
      await db
        .collection("users")
        .doc(userDetails.value.id)
        .update({
          photoURL: url.value
        });
    };

    const onLogout = async () => {
      await logout();
      window.location.reload();
    };

    return {
      isLoading,
      user,
      showDetails,
      toggleDetails,
      userDetails,
      updateUserDetails,
      updateUserPhotoURL,
      onLogout
    };
  }
};
</script>

<style scoped>
.separatorLine {
  background-color: #2a1f60;
  height: 1px;
  margin-top: 56px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 31, 96, 0.9);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: rgb(232, 233, 241);
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  display: block;
  margin-top: 1rem;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.logout-btn button {
  background: none;
  color: #2a1f60;
  padding: 0;
}
</style>
