<template>
  <form class="personal-details-form" @submit.prevent="$emit('update', form)">
    <div class="rowForm rowForm--photo-url">
      <img
        :src="userDetails.photoURL"
        alt="User avatar"
        class="avatarPreview"
      />
      <div class="rowItem">
        <label>Avatar</label>
        <input type="file" @change="onPhotoURLChange" />
      </div>
    </div>
    <div class="rowForm">
      <div class="rowItem">
        <label>First Name</label>
        <input
          v-model="form.firstName"
          type="text"
          placeholder="Your name"
          ref="projectList"
        />
      </div>
      <div class="rowItem">
        <label>Last Name</label>
        <input v-model="form.lastName" placeholder="Your last name" />
      </div>
    </div>
    <div class="rowForm">
      <div class="rowItem">
        <label>Working hours per day</label>
        <input
          v-model.number="form.workingHoursPerDay"
          type="number"
          min="1"
          max="24"
          placeholder="Maximum working hours per day"
        />
      </div>
    </div>
    <div class="rowForm">
      <BaseButton aria-label="update-profile" modifiers="primary">
        Update all
      </BaseButton>
    </div>
  </form>
  <form class="personal-details-form" @submit.prevent="updatePassword">
    <hr />

    <div class="rowForm">
      <div class="rowItem">
        <label>Current password</label>
        <input
          v-model="passwordForm.currentValue"
          type="password"
          placeholder="Current password"
        />
      </div>
    </div>

    <div class="rowForm">
      <div class="rowItem">
        <label>New password</label>
        <input
          v-model="passwordForm.newValue"
          type="password"
          placeholder="New password"
        />
      </div>
      <div class="rowItem">
        <label>New password confirmation</label>
        <input
          v-model="passwordForm.newValueConfirm"
          type="password"
          placeholder="New password confirmation"
        />
      </div>
    </div>

    <div class="rowForm">
      <BaseButton aria-label="update-password" modifiers="primary">
        Update password
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { reactive, inject } from "vue";

import { auth } from "@/firebase";
import getUser from "@/composables/getUser";
import BaseButton from "@/components/designsystem/BaseButton";

function getPasswordErrorMessage(error) {
  switch (error.code) {
    case "auth/wrong-password":
      return "Current password is not matching.";
    case "auth/wrong-confirm-password":
      return "New password and confirmation are not matching.";
    default:
      return error.message;
  }
}

export default {
  name: "EditPersonalDetails",
  components: { BaseButton },
  emits: ["update", "update-avatar"],
  props: {
    user: {
      type: Object,
      required: true
    },
    userDetails: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const toast = inject("$toast");
    const { user } = getUser();

    const form = reactive({
      photoURL: props.userDetails.photoURL,
      firstName: props.userDetails.firstName,
      lastName: props.userDetails.lastName,
      workingHoursPerDay: props.userDetails.workingHoursPerDay
    });

    const passwordForm = reactive({
      currentValue: "",
      newValue: "",
      newValueConfirm: ""
    });

    const updatePassword = async () => {
      try {
        if (
          passwordForm.newValue === "" ||
          passwordForm.newValue !== passwordForm.newValueConfirm
        ) {
          throw new Error("Passwords are not matching.");
        }

        await auth.signInWithEmailAndPassword(
          user.value.email,
          passwordForm.currentValue
        );

        await user.value.updatePassword(passwordForm.newValue);
        await toast("Password updated.", { type: "success" });
      } catch (e) {
        toast(getPasswordErrorMessage(e), { type: "error" });
      } finally {
        passwordForm.currentValue = "";
        passwordForm.newValue = "";
        passwordForm.newValueConfirm = "";
      }
    };

    const onPhotoURLChange = event => {
      const file = event.target.files[0];
      if (!file) return;

      emit("update-avatar", file);
    };

    return {
      form,
      passwordForm,
      updatePassword,
      onPhotoURLChange
    };
  },
  mounted() {
    const projectListRef = this.$refs.projectList;
    projectListRef.focus();
  }
};
</script>

<style lang="scss" scoped>
.personal-details-form {
  margin: 0 16px 32px 0;
}

.rowForm--photo-url {
  align-items: center;
}

.avatarPreview {
  border-radius: 150px;
  display: block;
  width: 80px;
  height: 80px;
  margin-right: 12px;
}

input[type="file"] {
  background-color: transparent;
  box-shadow: none;

  button {
    background: base-color !important;
  }
}
</style>
