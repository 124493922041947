<template>
  <div class="container">
    <div class="wrapper">
      <div class="titlesBlock">
        <h1 class="title">
          <router-view :to="{ name: 'Settings' }">Account</router-view>
        </h1>
      </div>

      <div>
        <component :is="selectedTab" class="tab"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import Profile from "@/views/settings/personal/Profile.vue";
// import Account from "@/views/settings/personal/Account.vue";

export default {
  name: "Settings",
  components: {
    Profile
    // Account
  },
  setup() {
    const tabs = ref(["Profile"]);
    const selectedTab = ref("Profile");

    return { tabs, selectedTab };
  }
};
</script>

<style lang="scss"></style>
