<template>
  <form>
    <div class="rowForm">
      <div class="avatarImg">
        <img
          :src="
            userDetails.photoURL ||
              'https://goodasdawn.com/images/Avatar-Empty.png'
          "
          class="logoAvatar"
        />
      </div>
    </div>
    <div class="rowForm">
      <div class="rowItem">
        <label>First Name</label>
        <h4>{{ userDetails.firstName }}</h4>
      </div>
      <div class="rowItem">
        <label>Last Name</label>
        <h4>{{ userDetails.lastName }}</h4>
      </div>
    </div>
    <div class="rowForm">
      <div class="rowItem">
        <label>Email address</label>
        <h4>{{ user.email }}</h4>
      </div>
      <div class="rowItem">
        <label>Working hours per day</label>
        <h4>{{ userDetails.workingHoursPerDay }}</h4>
      </div>
    </div>
    <div class="rowForm"></div>
  </form>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    userDetails: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.separatorLine {
  border: 1px solid $base-color;
  margin-top: 56px;
}
.logoAvatar {
  border-radius: 150px;
  height: 80px;
  width: 80px;
}
</style>
