import { ref } from "vue";
import { storage } from "@/firebase.js";
import getUser from "@/composables/getUser";

const useStorage = () => {
  const { user } = getUser();

  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);

  const uploadImage = async file => {
    const extension = file.name.split(".").pop();
    filePath.value = `avatars/${user.value.uid}.${extension}`;
    const storageRef = storage.ref(filePath.value);

    try {
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const deleteImage = async path => {
    const storageRef = storage.ref(path);

    try {
      await storageRef.delete();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  return { url, filePath, error, uploadImage, deleteImage };
};

export default useStorage;
